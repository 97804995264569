<template>
  <div class="container">
    <SiteHero />
    <FormularioComponent :product="product" />
    <PrimeirosCards />
    <CardsEmpresas />
    <hr class="custom-line" />
    <TrustSection />
    <img alt="Logo Ouro Fácil" src="@/assets/banner1.png" class="banner-girl" />
    <img
      alt="Logo Ouro Fácil"
      src="@/assets/banner-mobile.png"
      class="banner-girl-mobile"
    />
    <CalculatorSection @updateProduct="updateProduct" />

    <RecentPurchases />
    <TestimonialsSection />
    <SatisfactionGuarantee />
    <CaixaSection />
    <PhysicalStoreSection />
    <FaqSection />
    <FooterSection />

    <button @click="openWhatsApp" class="whatsapp-button">
      <img src="@/assets/whatsapp.svg" alt="WhatsApp" />
    </button>
  </div>
</template>

<script>
import SiteHero from './SiteHero.vue'
import FormularioComponent from './FormularioComponent.vue'
import PrimeirosCards from './PrimeirosCards.vue'
import CardsEmpresas from './CardsEmpresas.vue'
import TrustSection from './TrustSection.vue'
import CalculatorSection from './CalculatorSection.vue'
import RecentPurchases from './RecentPurchases.vue'
import TestimonialsSection from './TestimonialsSection.vue'
import SatisfactionGuarantee from './SatisfactionGuarantee.vue'
import PhysicalStoreSection from './PhysicalStoreSection.vue'
import FaqSection from './FaqSection.vue'
import { scrollToMixin } from '@/scrollToMixin'
import CaixaSection from './CaixaSection.vue'
import FooterSection from './FooterSection.vue'

export default {
  name: 'HomeDefault',
  components: {
    SiteHero,
    PrimeirosCards,
    FormularioComponent,
    CardsEmpresas,
    TrustSection,
    CalculatorSection,
    RecentPurchases,
    TestimonialsSection,
    SatisfactionGuarantee,
    PhysicalStoreSection,
    FaqSection,
    CaixaSection,
    FooterSection,
  },
  mixins: [scrollToMixin],
  data() {
    return {
      product: '',
    }
  },
  methods: {
    updateProduct(productInfo) {
      // Atualiza o valor do produto com base no evento emitido pelo CalculatorSection
      this.product = productInfo
      console.log('Product updated:', this.product)
    },
    openWhatsApp() {
      const phoneNumber = '+5537991117815'
      const url = `https://wa.me/${phoneNumber}`
      window.open(url, '_blank')
    },
  },
}
</script>

<style scoped>
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp-button img {
  width: 30px;
  height: 30px;
}

.custom-line {
  border: none;
  height: 1px;
  background-color: #9c9c9c;
  width: 1140px;
  margin-top: 40px;
}

.container {
  width: 100%;
  max-width: 100vw;
  padding: 0 24px;
  overflow-x: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-girl {
  width: 100vw;
  object-fit: contain;
}

.banner-girl-mobile {
  display: none;
}

@media only screen and (max-width: 720px) {
  .custom-line {
    max-width: 100%;
  }

  .banner-girl {
    display: none;
  }

  .banner-girl-mobile {
    display: block;
  }

  .cta-button {
    width: 100%;

    button {
      width: 100%;
      padding: 20px 50px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 480px) {
  .cta-button button {
    padding: 20px 0;
    font-size: 16px;
    margin-top: -30px;
  }
}
</style>
