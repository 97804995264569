<template>
  <div class="hero">
    <div class="content">
      <img
        alt="Logo Ouro Fácil"
        src="@/assets/logoouro.png"
        class="brand-logo"
      />

      <div class="hero-texts">
        <h1>
          VENDA SEU OURO DE FORMA SIMPLES E SEGURA - <br />
          <span> PAGAMENTO RÁPIDO E GARANTIDO!</span>
        </h1>

        <p>
          Leve seu ouro até um centro de avaliação perto da sua casa e receba o pagamento na hora! Uma transação privada, segura e fácil de fazer, sem riscos para você.
        </p>
      </div>

      <img class="girl-banner" src="@/assets/mulher.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteHeroDefault",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: "",
    },
  },
}
</script>

<style scoped>
.hero {
  background-image: url("@/assets/bg1.png");
  color: var(--background-color);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;

  margin-bottom: -0px;

  min-height: 75vh;
  max-height: 70vh;
  padding: 80px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .hero-texts {
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;

  }

  h1 {
    max-width: 780px;
    font-size: 28px;
  }

  .girl-banner {
    height: 100%;
    max-height: 800px;
    width: 100%;
    object-fit: contain;

  }
  
}

.video {
  width: 100%;
  max-width: 750px;
  height: 420px;
  background-color: #3d3227;
  border: 1px solid #695a4b;

  margin-bottom: 64px;
}

.brand-logo {
  max-width: 100%;
  object-fit: contain;
  max-width: 200px;
}

span {
  color: var(--primary-color);
  font-family: "Manrope", sans-serif;
}

p {
  max-width: 700px;

  margin-top: 0;
  font-family: Manrope;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

@media only screen and (max-width: 1367px) {
  .hero {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: -120px;
    
    .girl-banner {
      margin-top: -60px;
    }

    .content {
      max-width: 570px;

      .brand-logo {
        max-width: 100%;
        object-fit: contain;
        max-width: 150px;
      }

      h1 {
        font-size: 24px;
      }

      p {
        max-width: 680px;

        margin-top: 0;
        font-family: Manrope;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .hero {
    width: 100%;
    padding: 30px;


    min-height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: -14px;

   .girl-banner {
      margin-top: -120px;
      max-width: 100%;
      object-fit: contain;
    }
    
    .content {
      .brand-logo {
        max-width: 100px;
      }

      h1 {
        font-size: 22px;
        margin: 20px 0;
      }
      
      p {
        font-family: Manrope;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-align: center;
      }
    }
  }
  
  .video {
    max-width: 370px;
    max-height: 208px;
  }
}
</style>
