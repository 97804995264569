<template>
  <section class="satisfaction-section">
    <div class="satisfaction-content">
      <img
        src="@/assets/certificado.svg"
        alt="Selo de Satisfação"
        class="satisfaction-seal"
      />

      <h2>Nós garantimos a sua <span class="highlight">satisfação!</span></h2>

      <p>
        Se não gostar da nossa avaliação, é simples: basta recusar a oferta e
        enviaremos seus itens de volta sem nenhum custo. Além disso, você pode
        desistir da venda a qualquer momento, sem qualquer encargo. Nossa
        prioridade é que você tenha uma experiência segura e sem preocupações.
      </p>

      <button class="cta-button" @click="scrollToSection('#form-content')">
        SOLICITAR ORÇAMENTO GRATUITO
      </button>
    </div>
  </section>
</template>

<script>
import { scrollToMixin } from '@/scrollToMixin';

export default {
  name: 'SatisfactionGuarantee',
  mixins: [scrollToMixin],
};
</script>

<style scoped lang="scss">
.satisfaction-section {
  background: url('@/assets/bg2.png') no-repeat center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 0;
  margin-top: 120px;
  text-align: center;
  width: 100vw;
}

.satisfaction-content {
  max-width: 800px;
  margin: 0 auto;
  color: #fff;

  .satisfaction-seal {
    width: 350px;
    margin-bottom: 20px;
    margin-top: -180px;
  }

  h2 {
    font-family: 'Manrope', sans-serif;
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 20px;

    .highlight {
      color: #e1b026;
    }
  }

  p {
    font-family: 'Manrope', sans-serif;
    font-size: 22px;
    line-height: 1.6;
    margin-bottom: 30px;
  }

  .cta-button {
    background-color: #e1b026;
    border: none;
    padding: 15px 30px;
    border-radius: 5px;
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e0a320;
    }
  }
}

@media only screen and (max-width: 720px) {
  .satisfaction-section {
    background-size: cover;
    width: 100%;
    padding: 60px 30px;
    margin-top: 30px;

    background: url('@/assets/bg-2-mobile.jpeg') no-repeat center center;
    background-size: cover;
    background-repeat: no-repeat;

    .satisfaction-content {
      img {
        width: 240px;
        margin-top: -90px;
      }
    }

    p {
      font-family: Manrope;
      padding: 10px;
      font-size: 17px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 480px) {
  .satisfaction-section {
    h2 {
      font-family: Manrope;
      font-size: 28px;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
    }

    p {
      font-family: Manrope;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      text-align: center;
    }
  }
}
</style>
