<template>
  <section class="calculator-section">
    <div class="calculator-content">
      <div class="calculator-texts">
        <h2>
          Descubra o Valor Atual do Seu Ouro -
          <span class="highlight">Calcule Agora!</span>
        </h2>
        <p>
          Use nossa calculadora para obter uma estimativa do valor atual de
          mercado do ouro. Lembre-se, este valor é apenas uma referência. A
          oferta final, que pode ser ainda mais vantajosa, será determinada após
          nossa avaliação especializada.
        </p>
      </div>

      <div class="calculator-form">
        <div class="calculator-group">
          <div class="btns-and-slides">
            <div class="calculator-options">
              <button
                :class="{ active: material === 'ouro' }"
                @click="selecionarMaterial('ouro')"
              >
                OURO
              </button>
              <button
                :class="{ active: material === 'prata' }"
                @click="selecionarMaterial('prata')"
              >
                PRATA
              </button>
            </div>

            <div class="slides" v-if="material === 'ouro'">
              <div class="slider-group">
                <label>Selecione o peso em gramas</label>
                <input type="range" v-model="peso" min="1" max="100" />
                <span>{{ peso }}g</span>
              </div>

              <div class="slider-group">
                <label>Selecione o quilate</label>
                <input
                  type="range"
                  v-model="quilate"
                  :min="10"
                  :max="24"
                  :step="2"
                />
                <span>{{ quilate }}K</span>
              </div>
            </div>

            <span class="arraste"
              >Arreste para o lado e <strong>calcule</strong></span
            >
          </div>

          <div class="right-content">
            <div class="result-box" v-if="material === 'ouro'">
              <p>Valor em tempo real:</p>
              <h3>
                {{
                  valorAtual.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                }}
              </h3>
            </div>
            <div class="cta-button">
              <button @click="scrollToSection('#form-content')">
                QUERO RECEBER ESSA OFERTA
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

export default {

<script>
import { scrollToMixin } from '@/scrollToMixin'

export default {
  mixins: [scrollToMixin],
  data() {
    const precoAntigo18 = 180;
    const novoPreco18 = 230;
    const proporcao = novoPreco18 / precoAntigo18;

    return {
      material: 'ouro',
      peso: 1,
      quilate: 24,
      valorAtual: 240,
      precosPorQuilate: {
        10: 100 * proporcao,
        12: 120 * proporcao,
        14: 140 * proporcao,
        16: 160 * proporcao,
        18: novoPreco18,
        20: 200 * proporcao,
        22: 220 * proporcao,
        24: 240 * proporcao,
      },
    }
  },
  watch: {
    peso() {
      this.calcularValor()
    },
    quilate() {
      this.calcularValor()
    },
    material() {
      this.calcularValor()
    },
  },
  methods: {
    calcularValor() {
      if (this.material === 'ouro') {
        const precoPorGrama = this.precosPorQuilate[this.quilate] || 0
        this.valorAtual = this.peso * precoPorGrama

        // Emitir o valor do produto atualizado
        const productInfo = `${this.peso}g de ${this.material} ${this.quilate}K`
        this.$emit('updateProduct', productInfo)
      }
    },
    selecionarMaterial(material) {
      if (material === 'prata') {
        alert(
          'Pedimos desculpas, mas no momento nosso estoque de prata está completo e não estamos realizando novas compras. Agradecemos a sua compreensão.'
        )
      } else {
        this.material = material
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.calculator-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 40px;
  background-color: #f3f3f3;

  width: 100%;

  .arraste {
    margin-left: 20px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #000000;
    border: 1px solid #a7a7a7;
    padding: 10px 15px;
    margin-top: 30px;
    border-radius: 50px;
  }
}

.calculator-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  max-width: 1140px;
  width: 100%;
  max-height: 840px;
}

// Texts and headings
h2 {
  font-family: 'Manrope', sans-serif;
  font-size: 24px;
  margin-bottom: 15px;
  max-width: 390px;
  text-align: left;
}

.highlight {
  color: #e1b026;
}

p {
  font-family: 'Manrope', sans-serif;
  color: #000;
  font-size: 20px;
  margin-bottom: 20px;
  max-width: 750px;
  text-align: left;
}

// Calculator form
.calculator-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 400px;
}

.calculator-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  gap: 40px;
}

.btns-and-slides {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fcfcfc;
  border-radius: 20px;
  width: 100%;
  max-width: 560px;
  min-height: 370px;
}

// Buttons for selecting material
.calculator-options {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button {
    background-color: #f2f2f2;
    padding: 20px 30px;
    border: none;
    border-top-left-radius: 20px;
    background: linear-gradient(180deg, #ffffff 0%, #dcdcdc 100%);

    + button {
      border-radius: 0;
      border-top-right-radius: 20px;
    }

    cursor: pointer;
    font-family: 'Manrope', sans-serif;
    font-size: 20px;
    color: #000;
    font-weight: bold;
    width: 100%;

    &.active {
      background: #f3c441;
      border: none;
    }
  }
}

.help-text {
  margin-left: 40px;
  margin-top: 20px;
  padding: 15px 28px;
  border: 1px solid #a7a7a7;
  border-radius: 50px;
  color: #000000;
}

// Sliders for weight and quality
.slider-group {
  padding: 0 40px;
  margin-top: 30px;
  width: 480px;

  label {
    text-align: left;
    font-family: 'Manrope', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    display: block;
    margin-bottom: 5px;
    width: 100%;
  }

  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
  }

  /***** Chrome, Safari, Opera, and Edge Chromium *****/
  input[type='range']::-webkit-slider-runnable-track {
    background: #837e72;
    height: 0.1rem;
  }

  /******** Firefox ********/
  input[type='range']::-moz-range-track {
    background: #837e72;
    height: 0.1rem;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -12px; /* Centers thumb on the track */
    background-color: #f3c441;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.6rem;
  }
}

.right-content {
  width: 45%;

  .cta-button {
    margin-top: 20px;
    padding-bottom: 36px;
  }

  .cta-button button {
    width: 100%;
    background: linear-gradient(90deg, #e1b026 0%, #f3c441 100%);
    color: white;
    border: none;
    padding: 12px 30px;
    border-radius: 5px;
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    cursor: pointer;
  }

  .cta-button button:hover {
    background-color: #fba022;
  }
}
// Result box
.result-box {
  max-width: 485px;
  height: 240px;
  width: 100%;
  background: linear-gradient(90deg, #3a3832 0%, #262520 100%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding-left: 50px;

  p {
    font-size: 24px;
    margin-bottom: 10px;
    color: #fff;
  }

  h3 {
    font-size: 72px;
    color: #e1b026;
    margin: 0;
  }
}

@media only screen and (max-width: 1170px) {
  .calculator-section {
    min-height: 800px;
    align-items: flex-start;
    padding-bottom: 200px;
  }

  .calculator-content {
    min-height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .right-content {
    width: 53%;
  }

  .calculator-group {
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 720px) {
  .calculator-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 900px;
    padding: 50px 30px;

    h2 {
      text-align: center;
    }

    p {
      font-size: 17px;
    }

    .btns-and-slides {
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .slider-group {
        max-width: 340px;
      }
    }
  }

  .calculator-form {
    max-height: auto !important;
  }

  .help-text {
    margin-left: 0;
    margin-bottom: 24px;
  }

  .calculator-group {
    max-width: 360px;

    .result-box {
      width: 100%;
      padding: 30px auto !important;
      padding-left: 0;
      height: 160px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        font-size: 20px;
        margin: 0;
      }

      h3 {
        font-size: 48px;
      }
    }
  }

  .calculator-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 1200px;
  }

  p {
    text-align: center;
    max-width: 370px;
  }
}

@media only screen and (max-width: 480px) {
  .calculator-section {
    max-width: 100vw;
    padding-bottom: 10px;

    .calculator-content {
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .calculator-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .right-content {
      width: 100%;
    }

    .calculator-group {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    h2 {
      font-family: Manrope;
      font-size: 26px;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
    }

    p {
      font-family: Manrope;
      font-size: 17px;
      font-weight: 400;
      line-height: 19px;
      text-align: center;
    }

    .calculator-content {
      display: flex;
      align-items: flex-start;
    }

    .btns-and-slides {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      min-height: 350px;

      .slides {
        padding: 0 20px;
        box-sizing: border-box;
        width: 100%;

        .slider-group {
          width: 100%;
          max-width: 360px;
          margin-top: 20px;
          padding: 0;
        }
      }
    }
  }
}
</style>
