<template>
  <section class="testimonials-section">
    <button class="feedback-btn">Feedbacks</button>

    <div class="testimonials-header">
      <h2>
        Confira o depoimento de algumas pessoas que já venderam ouro para nós e
        <span class="highlight"
          >descobriram como é fácil e seguro transformar seus objetos de valor
          em dinheiro!</span
        >
      </h2>
    </div>

    <SliderComponent />

    <!-- Caixa de depoimentos com placeholders -->
    <div class="testimonial-boxes">
      <div class="testimonial-box">
        <iframe
          width="100%"
          height="240"
          src="https://player.vimeo.com/video/1022993543?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="testimonial-box">
        <iframe
          width="100%"
          height="240"
          src="https://player.vimeo.com/video/1022993506?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="testimonial-box">
        <iframe
          width="100%"
          height="240"
          src="https://player.vimeo.com/video/1022993462?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>

    <!-- Depoimento ativo em carrossel -->

    <swiper
      :slides-per-view="1"
      :navigation="true"
      autoplay
      loop="true"
      class="testimonial-carousel"
      speed="500"
    >
      <swiper-slide
        v-for="item in items"
        :key="item.id"
        class="testimonial-content"
      >
        <p class="testimonial-quote">
          <span class="quote-icon">❝ <br /> </span> {{ item.testimonial }}
        </p>
        <div class="testimonial-author">
          <img :src="item.image" class="author-image" />
          <div class="author-info">
            <p class="author-name">{{ item.name }}</p>
            <p class="author-location">{{ item.city }}</p>
          </div>
        </div>
      </swiper-slide>
    </swiper>

    <!-- Botão de CTA -->
    <div class="cta-button" @click="scrollToSection('#form-content')">
      <button>QUERO VENDER MINHAS JÓIAS TAMBÉM</button>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'

export default {
  name: 'TestimonialsSection',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      items: [
        {
          id: 1,
          testimonial:
            'Vendi uma pulseira antiga que eu tinha guardada por R$3.000. O processo foi rápido e simples, e o valor recebido foi justo. Esse dinheiro veio em boa hora e me ajudou bastante. Recomendo a Ouro Fácil Brasil para quem quer vender com segurança.',
          image: require('@/assets/perfis/perfil1.jpg'),
          name: 'João Silva',
          city: 'Rio de Janeiro',
        },
        {
          id: 2,
          testimonial:
            'Enviei alguns anéis de ouro que não usava mais e fiquei surpresa com o valor de R$2.500 que recebi. A negociação foi transparente, e o atendimento, muito atencioso. Recomendo a Ouro Fácil Brasil pela confiança e rapidez.',
          image: require('@/assets/perfis/perfil2.jpg'),
          name: 'Ana Paula',
          city: 'São Paulo',
        },
        {
          id: 3,
          testimonial:
            'Vendi um colar antigo da minha família por R$4.200 e fiquei muito satisfeita com o atendimento e o valor oferecido. A equipe foi extremamente profissional e o processo foi muito seguro. Com certeza voltarei a fazer negócios no futuro.',
          image: require('@/assets/perfis/perfil3.jpg'),
          name: 'Carlos Eduardo',
          city: 'Belo Horizonte',
        },
        {
          id: 4,
          testimonial:
            'Estava precisando de dinheiro rápido e decidi vender algumas joias antigas. Recebi R$1.800 pela venda e fiquei impressionado com a rapidez do pagamento. A Ouro Fácil Brasil é uma empresa séria e confiável.',
          image: require('@/assets/perfis/perfil4.jpg'),
          name: 'Fernanda Souza',
          city: 'Curitiba',
        },
        {
          id: 5,
          testimonial:
            'Vendi alguns brincos de ouro que estavam guardados há anos e recebi R$3.500. O processo foi muito eficiente e não tive nenhuma preocupação. Fiquei muito satisfeita com a experiência e recomendo a Ouro Fácil Brasil para todos.',
          image: require('@/assets/perfis/perfil5.jpg'),
          name: 'Roberta Palhares',
          city: 'Salvador',
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.testimonials-section {
  padding: 40px;
  padding-bottom: 220px;
  text-align: center;
  background: #fff;
  width: 100%;
  margin-bottom: -150px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .testimonial-box video {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .feedback-btn {
    border: 1px solid #d6d6d6;
    padding: 10px 35px;
    border-radius: 100px;
    cursor: pointer;
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    color: #000000;
  }

  .testimonials-header {
    margin-bottom: 30px;

    h2 {
      font-family: 'Manrope', sans-serif;
      font-size: 32px;
      font-weight: 700;
      color: #000;
      margin-bottom: 15px;
      max-width: 840px;

      .highlight {
        color: #e1b026;
      }
    }
  }

  .testimonial-boxes {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 80px;

    .testimonial-box {
      width: 365px;
      height: 208px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .placeholder {
      background-color: #e1b026;
    }
  }

  .testimonial-carousel {
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 750px;
    height: 330px;
    margin: 0 auto;
    width: 100%;
    gap: 50px;
    padding: 35px 0;

    border-radius: 10px;
    background-color: #f5f5f5;
    border: 1px solid #d9d9d9;

    .testimonial-content {
      width: 600px;
      height: 100%;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      .testimonial-quote {
        padding: 0 48px;
        font-family: 'Manrope', sans-serif;
        font-size: 20px;
        line-height: 1.5;
        color: #000000;
        margin: 0;

        .quote-icon {
          color: #e1b026;
          font-size: 50px;
        }
      }

      .testimonial-author {
        display: flex;
        align-items: center;
        padding: 0 48px;

        .author-image {
          width: 65px;
          height: 65px;
          border-radius: 6px;
          margin-right: 15px;
          background-color: #e1b026;
          object-fit: cover;
        }

        .author-info {
          .author-name {
            font-family: 'Manrope', sans-serif;
            font-size: 20px;
            font-weight: bold;
            color: #000;
            margin: 0;
          }

          .author-location {
            font-family: 'Manrope', sans-serif;
            font-size: 15px;
            color: #666;
            margin: 0;
          }
        }
      }
    }
  }

  .cta-button {
    margin-top: 40px;

    button {
      background: linear-gradient(90deg, #e1b026 0%, #f3c441 100%);
      border: none;
      padding: 15px 30px;
      border-radius: 5px;
      font-family: 'Manrope', sans-serif;
      font-size: 16px;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #e0a320;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .testimonials-header {
    font-family: Manrope;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
  }

  .testimonials-section {
    .testimonial-carousel {
      height: auto;
    }
  }
}

@media only screen and (max-width: 480px) {
  .testimonials-section {
    .testimonials-header h2 {
      font-family: Manrope;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      text-align: center;
      margin-bottom: 0;
    }
    .testimonial-boxes {
      width: 100%;
      gap: 50px;
    }

    .testimonial-boxes .testimonial-box {
      border-radius: 2px;
    }

    .testimonial-carousel {
      height: 280px;

      .testimonial-content {
        .testimonial-quote {
          font-family: Manrope;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          margin-bottom: 20px;
        }
      }
    }

    .cta-button {
      width: 100%;

      button {
        padding: 20px auto;
        font-size: 15px;
      }
    }
  }
}
</style>
